<template>
  <van-pull-refresh v-model="isLoading" pulling-text="Tarik ke bawah untuk refresh..."
    loosing-text="Lepaskan untuk refresh..." success-text="Refresh Berhasil" loading-text=" " @refresh="onRefresh">
    <div class="content">
      <div class="top">
        <div class="title_img">
          <h4 class="name">
            <span>Pasar pinjaman</span>
            <span v-if="enhedensdige" @click="visibleLogOut = true">Keluar</span>
          </h4>
          <div class="Semua_orabg_meminjam">
            <img src="@/assets/img/Semua_orabg_meminjam.png" />
          </div>
          <div class="productBox">
            <img src="@/assets/img/product_icon.png" alt="" />
            <span>Buku Dana</span>
          </div>
        </div>

        <div class="top_bg">
          <div class="title">
            {{
              this.form.loanStatus == 5
                ? " Total Pembayaran"
                : "Pinjaman Maksimum"
            }}
          </div>
          <div class="jine">
            Rp {{ formatNumberWithCommas(this.form.loanMoney) }}
          </div>
          <!-- 状态1到9 -->
          <div class="jeikaun_xinxi" v-if="this.form.loanStatus != 5">
            <div class="li_lv">
              <div>Bunga Pinjaman</div>
              <span>{{ this.form.interestRate }}</span>
            </div>
            <div class="tian_shu">
              <div>Tenor Pinjaman</div>
              <span>{{ this.form.loanTerm }} Hari</span>
            </div>
          </div>
          <!-- 状态5 -->
          <div class="jeikaun_xinxi" v-else>
            <div class="li_lv">
              <div>Tanggal Pembayaran</div>
              <span>{{ this.form.interestRate }}</span>
            </div>
            <div class="tian_shu">
              <div>Jumlah Hari Keterlambatan</div>
              <span>{{ this.form.loanTerm }} Hari</span>
            </div>
          </div>
          <van-notice-bar color="#333" background="none" :left-icon="trumpet" @click="visible = true">
            <span class="inform" v-for="(item, index) in this.form.msgList" :key="index">{{ item }}</span>
          </van-notice-bar>
          <!-- 状态1-9按钮 -->
          <img v-if="this.form.loanStatus != 5" class="pinjam_uang" src="@/assets/img/pinjam_uang.png"
            @click="goHome" />
          <!-- 状态5按钮 -->
          <img v-else class="pinjam_uang" src="@/assets/img/pembayaran.png" @click="goHome" />
        </div>
      </div>
      <div class="bottom">
        <div class="productCard" v-for="(item, index) in this.form.marketList" :key="index" @click="goOther(item)">
          <div class="product">
            <img :src="item.product_logo" alt="" />
            <div class="productName">
              <div>{{ item.product_name }}</div>
              <span :style="{
                color: item.product_status == 5 ? '#FF9A37' : '#666666',
              }">{{ item.product_title }}</span>
            </div>
            <div :class="item.product_click == 1 ? 'text_btn' : 'text_btn_disenable'
              ">
              {{ item.product_button }}
            </div>
          </div>
          <div class="juta" v-for="(productItem, productIndex) in item.product_list" :key="productIndex">
            <div v-for="(value, key) in productItem" :key="key">
              <span>{{ key }}:</span>
              <span>{{
                typeof value === "number"
                  ? formatNumberWithCommas(value)
                  : value
              }}</span>
            </div>
          </div>

          <div class="describe" v-if="item.msgList.length > 0">
            <div v-for="(msgItem, msgIndex) in item.msgList" :key="msgIndex">
              {{ msgItem }}
            </div>
          </div>
        </div>
      </div>

      <div class="advertising" v-show="advertisingShow" @click="goAdvertising">
        <img src="@/assets/img/advertising_close.png" @click="advertisingShow = false" />
      </div>
      <van-dialog v-model="visible" :showConfirmButton="false">
        <div class="dialog">
          <p class="title">{{ this.form.msgList[0] }}</p>
          <p class="tip">
            {{ this.form.msgList[1] }}
          </p>
          <button type="button" class="btn" @click="visible = false">
            Setuju
          </button>
        </div>
      </van-dialog>
    </div>
    <van-dialog v-model="visibleLogOut" show-cancel-button cancel-button-text="Batal" confirm-button-text="Setuju"
      @cancel="visibleLogOut = false" @confirm="logOut">
      <h3 class="logout_text">yakin untuk keluar?</h3>
    </van-dialog>
    <van-dialog v-model="visibleHint" :showConfirmButton="false">
      <div class="hint_box">
        <img class="hint" src="@/assets/img/hint.png" alt="" />
        <img class="img_btn" src="@/assets/img/hint_btn.png" alt="" @click="visibleHint = false" />
      </div>
    </van-dialog>
  </van-pull-refresh>
</template>

<script>
import request from '@/utils/request'
import Vue from 'vue'
import { NoticeBar, Dialog, PullRefresh } from 'vant'
import dialogVisible from '@/mixins/dialogVisible'
import trumpet from '@/assets/img/trumpet.png'
import store from '@/store'
import router from '@/router'
import Cookies from 'js-cookie'

Vue.use(NoticeBar)
Vue.use(PullRefresh)

export default {
  name: 'Home1',
  mixins: [dialogVisible],
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  data () {
    return {
      trumpet: trumpet,
      isLoading: false,
      isRequest: false,
      advertisingShow: true,
      visibleLogOut: false,
      visibleHint: false,
      form: {
        marketList: [],
        msgList: [],
        product_list: []
      },
      enhedensdige: !!sessionStorage.getItem('enhedensdige')
    }
  },
  created () {
    this.getState()
    const t = this
    // 添加 visibilitychange 事件监听器
    document.addEventListener('visibilitychange', function () {
      if (document.visibilityState === 'visible') {
        // 页面变为可见时执行的逻辑
        t.getState()
      }
    })
  },
  methods: {
    // 下拉刷新
    onRefresh () {
      this.getState()
    },
    // 获取状态
    getState () {
      request.post('marketHomepage').then(res => {
        this.form = res
        this.isLoading = false
      })
    },
    // 去首页
    goHome () {
      if (this.form.switch === 2) {
        this.visibleHint = true
        return
      }
      if (this.form.switch === 1) {
        this.$router.push({
          path: '/home'
        })
      }
    },
    // 跳转到其他项目
    goOther (item) {
      if (this.isRequest) {
        return
      }
      this.isRequest = true
      if (item.switch === 2) {
        this.visibleHint = true
        this.isRequest = false
        return
      }
      const phone = item.jump_url.match(/phone=([^&]*)/)[1]
      // 给定的完整 URL 字符串
      const urlString = item.jump_url
      // 使用字符串截取方法获取不带参数的跳转地址
      let redirectUrl = urlString.split('?')[0]
      // 判断最后一位是否是 '/'
      if (redirectUrl.endsWith('/')) {
        // 去掉最后一位
        redirectUrl = redirectUrl.slice(0, -1)
      }
      const abcdefgh = sessionStorage.getItem('abcdefgh')
      let obj = {}
      if (abcdefgh) {
        obj = JSON.parse(abcdefgh)
      }
      // 获取登录信息  UC
      request.post('https://maapi.nyxlyn.cc/queryUserInfo', { phone }).then(res => {
        window.location = `${redirectUrl}/transfer?data=${JSON.stringify(res)}&kimarAlama=${sessionStorage.getItem('enhedensdige')}&SSO=1&a=${obj.a}&b=${obj.b}&c=${obj.c}&d=${obj.d}&e=${obj.e}&f=${obj.f}&g=${obj.g}&h=${obj.h}&j=${obj.j}&k=${obj.k}`
      }).finally(() => {
        setTimeout(() => {
          this.isRequest = false
        }, 300)
      })
    },
    // 退出登录
    logOut () {
      store.commit('recordInfo', {
        type: 'userBKInfo',
        value: {}
      })
      window.localStorage.removeItem('userBKInfo')
      Cookies.remove('userBKInfo')
      router.push({
        path: '/login'
      })
    },
    // BRD
    goAdvertising () {
      window.location = 'https://apps.apple.com/id/app/done-cepat/id6738672838?l=id'
    },
    formatNumberWithCommas (number) {
      if (!number) {
        return ''
      }
      // 先将数字转换为字符串
      const numStr = String(number)

      // 使用正则表达式匹配数字的整数部分和小数部分
      const parts = numStr.split('.')
      let integerPart = parts[0]
      const decimalPart = parts.length > 1 ? '.' + parts[1] : ''

      // 对整数部分添加千分位分隔符
      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.')

      // 拼接整数部分和小数部分
      return integerPart + decimalPart
    }
  }
}
</script>
<style scoped lang="less">
.content {
  background: #f0f2f5;
  min-height: 100vh;

  .advertising {
    height: 90px;
    width: 100%;
    position: fixed;
    bottom: 0;
    background: url("~@/assets/img/advertising_bg.png") no-repeat;
    background-size: 100% 100%;

    img {
      position: absolute;
      width: 28px;
    }
  }
}

.top {
  .title_img {
    background: #ff4141;
    padding-left: 28px;

    .name {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 40px;
      padding-bottom: 25px;
      padding-right: 28px;

      span {
        font-weight: 600;
        font-size: 34px;
        color: #ffffff;
      }
    }

    .Semua_orabg_meminjam {
      padding-bottom: 47px;

      img {
        height: 87px;
      }
    }

    .productBox {
      display: flex;
      align-items: center;

      img {
        width: 99px;
        height: 99px;
        margin-right: 20px;
      }

      span {
        font-weight: 600;
        font-size: 34px;
        color: #ffffff;
      }
    }
  }

  .top_bg {
    position: relative;
    height: 560px;
    background: url("~@/assets/img/daichao_bg.png") no-repeat;
    background-size: 100% 100%;

    /deep/ .van-icon__image {
      width: 27px;
      height: 26px;
      margin-right: 10px;
    }

    /deep/ .van-notice-bar {
      border-radius: 60px;
      margin: 0 auto;
      width: 610px;
      height: 47px;
      background-color: rgba(255, 255, 255, 0.3) !important;
    }

    .title {
      text-align: center;
      font-weight: 600;
      font-size: 28px;
      color: #ffffff;
      padding-top: 36px;
      margin-bottom: 19px;
    }

    .jine {
      margin: 0 auto;
      width: 480px;
      height: 97px;
      background: #f24736;
      border-radius: 67px;
      font-size: 51px;
      color: #ffffff;
      line-height: 97px;
      text-align: center;
      font-weight: bold;
    }

    .jeikaun_xinxi {
      display: flex;
      justify-content: space-evenly;
      margin-top: 30px;
      margin-bottom: 34px;
      font-size: 26px;

      .li_lv,
      .tian_shu {
        flex: 1;
        text-align: center;

        div {
          font-weight: 600;
          font-size: 26px;
          color: #ffffff;
          margin-bottom: 8px;
        }

        span {
          font-weight: bold;
          font-size: 40px;
          color: #ffffff;
        }
      }
    }

    .pinjam_uang {
      position: absolute;
      bottom: -49px;
      left: 50%;
      transform: translateX(-50%);
      height: 98px;
    }
  }
}

.bottom {
  padding: 78px 32px;

  .productCard {
    margin-bottom: 37px;
    width: 634px;
    background: #ffffff;
    border-radius: 28px;
    border: 1px solid #e3e3e3;
    padding: 26px;
    padding-top: 36px;
    padding-bottom: 10px;

    .product {
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        width: 95px;
        height: 95px;
        margin-right: 10px;
      }

      .productName {
        font-size: 22px;

        div {
          font-weight: 600;
          font-size: 30px;
          color: #333333;
          margin-bottom: 8px;
        }

        span {
          font-weight: 400;
          font-size: 22px;
          color: #666666;
        }
      }

      .text_btn {
        margin-left: auto;
        width: 238px;
        height: 65px;
        background: #ffffff;
        border-radius: 13px;
        border: 2px solid #15b087;
        font-weight: 600;
        font-size: 24px;
        color: #21b089;
        line-height: 65px;
        text-align: center;
      }

      .text_btn_disenable {
        .text_btn;
        background: #efefef;
        border-color: #efefef;
        color: #d8d8d8;
      }
    }
  }

  .juta {
    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      height: 69px;
      background: #f8f8f8;
      margin-top: 21px;
      padding: 0 20px;

      span:nth-child(2) {
        font-weight: 600;
        font-size: 26px;
        color: #333333;
      }
    }

    div:nth-child(odd) {
      font-weight: 400;
      font-size: 26px;
      color: #666666;
    }

    div:nth-child(even) {
      font-weight: 400;
      font-size: 26px;
      color: #666666;
      background: none;
      margin-top: 0;
      height: 80px;
    }
  }

  .describe {
    padding: 20px;
    padding-top: 10px;
    background: #f8f8f8;
    font-weight: 400;
    font-size: 26px;
    color: #999999;
    line-height: 37px;
    margin-bottom: 30px;
  }
}

.dialog {
  padding: 40px 30px;

  .title {
    font-size: 50px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .tip {
    font-size: 35px;
    font-weight: 500;
    margin-bottom: 70px;
  }

  .btn {
    width: 100%;
    height: 100px;
    border-radius: 15px;
    font-size: 40px;
    font-weight: 600;
    color: #fff;
    background: #3a3a3a;
  }
}

.logout_text {
  color: #000;
  font-size: 46px;
  margin: 60px 0px;
  text-align: center;
}

.hint_box {
  position: relative;

  .hint {
    width: 100%;
  }

  .img_btn {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 500px;
  }
}
</style>
